.App {
  text-align: center;
}
.App {
  margin: 1rem auto;
  max-width: 1000px;
  padding: 1rem;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000003a;
  transition: opacity 0.2s ease;
}

html{overflow-y: hidden;
background-color:#cbd4fd ;}

nav {
  background-color: #282c34;
  height: 7vh;
  display: flex;
  align-items: center;

  font-size: calc(5px + 2vmin);
  color: white;
}
nav li{
  list-style: none;
  padding-left: 5vh;
}
nav>li>ul{
  font-size: calc(2vmin);
  display: none;
}
.content{
  display:flex;
 flex-direction:column;
 height:90vh;
 overflow-y:auto;
 overflow-x:hidden;
}
footer>div{
  display: none;
  }
footer>div li:hover{
  cursor: pointer;
  background-color: whitesmoke;
  color: #282c34;
}
nav a{text-decoration:none; color: white;}
li>a {
  color: inherit;
  text-decoration: none;
  padding: 0;
  margin: 0;
  height: 100%;
}
footer{
  flex-direction: column;
  display: flex;
  position:sticky;
  justify-content: space-evenly;
  justify-self: flex-end;
  align-self: flex-end;
  bottom: 0;

  width: 100%;
  height: 3vh;
  min-height: 20px;
  justify-content: center;
  padding-top: 0;
}
footer li{
  display: flex;
  list-style: none;
  align-self: stretch;
  justify-content: center;
  padding-top: 0;
  width: 100%;
}
footer> ul{ 
  display:flex;  
  justify-content: space-evenly;
  flex-direction: row;
  margin: 0;
  padding:0;
}
footer> span{
  display: flex;
 
  width: 100;
  justify-content: center;
  text-align: center;
  height: 2vh;
  background-color: #282c34;
}
.contactBttn{
  display: flex;
  background-color: #528227;
  color: white;
  margin: 1em;
  padding: 0.4em;
}
.
 li>a{
   display: flex;
   color: inherit;
   text-decoration: none;
   justify-self: stretch;
   align-self: stretch;
   width: 100%;
   height: 100%;
   justify-content: center;
   padding: 0;
   margin: 0;
 }
 
@media screen and (max-width: 900px){
 
  footer{
    height: 8vh;
    background-color: #282c34;
    color: white;
  }
  footer>div{
    padding: 0;
    margin:0;
    display: flex;}
}
@media screen and (max-width: 650px){
  .content{
    height: 85vh;
  }
  

}