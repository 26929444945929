 .about{
    display: flex;
    background-color: white;
    border: 1px solid black;
    justify-content: space-between;
    height: 38vh;
    align-content: center;
    align-self: center;
    margin: 0.5em;
    padding: 0.25em;
    box-shadow: 3px 3px 2px 0px rgba(0, 0, 0, 0.37);
    max-height: 400px;
  
  
  }
  .about div{
    padding:0.25em;
    flex-direction: column;
  }
  .bio{
    display: flex;
    width: 80%;
    justify-content: center;
    align-content: center;
    font-size: 1.5em;
  }
  .headshot{
    
    display:flex;
    align-self: stretch;
    justify-self: stretch; 
    min-width: 25%;
    padding: 1em;
    margin-right: 1.5em;
    height: 100%;
    justify-content: center;
    align-content: center;
  }
  .HeadShot{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 80%;
    max-width: 375px;
    margin: 1em;
  }

  @media screen and (max-width: 900px){
    .about {
        flex-direction: row;
        margin: 0;
        box-shadow: none;
        max-height: 300px;
        
      }
      .bio{
        display: flex;
        width: 60%;
        justify-content: center;
        align-content: center;
        font-size: 1.5em;
      }
      .headshot{
    
        display:flex;
        align-self: stretch;
        justify-self: stretch; 
        width: 50%;
        padding: 1em;
        margin-right: 1.5em;
        height: 100%;
        justify-content: center;
        align-content: center;
        
      }
      .HeadShot{
        display: flex;
        
        align-self: center;
        width: 50%;
        max-width: 375px;
        margin: 1em;
      }
      .about div{
        padding:0.25em;
        flex-direction: column;
      }
  }
  @media screen and (max-width: 650px){
    .about{
      border: none;
      width: 100vm;
      padding: 0;
      margin: 0;
      height: fit-content;
      max-height: none;
      justify-content: center;
      align-content: center;
      font-size: 1em;
     
    }
    .about img{
      max-width: 200px;
      align-self: center;
  
    }
    .bio{
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 80%;
        justify-content: center;
        align-self: center;
        align-content: center;
        flex-direction: column;
        padding: 1em;
        padding-left: 0;
        font-size: 1.25em;
        margin: 0;
        background-color: rgba(255, 255, 255, 0.5)

        

    }

    .bio h3{
        font-size: 1.15em;
        padding-left: 3px ;
        border-bottom: 3px solid black;
        width: 90%;
        margin-bottom: 1em;
        
        
    }
  .bio p{
    margin: 5px;
    font-size: 0.75em;
  }
  .headshot{
    
    display:flex;
    align-self: center;
    justify-self: stretch; 
    width: 30vw;
    height: 30vw;
    padding: 0;
    margin: 0;
    height: auto;
    
    justify-content: center;
    align-content: center;
    overflow: hidden;
  }
  .HeadShot{
    display: flex;
    
    align-self: center;
    width: 100%;

  }
  }