  .show {
    display: block;
  }
  
  .hide {
    display: none;
  }
  
  .overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000003a;
    
  }
  
  .modal {
    width: 50vw;
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-self: center;
    top:40px;
    margin-left: 20vw;
    padding: 20px;
    background-color: #fff;
    transition: transform 0.2s ease;
    box-shadow: 0 2px 8px 3px;
    font-family: Helvetica, Arial, sans-serif;
  }
  .closeModal
  .modal {
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    margin: 0 auto;
    
    box-shadow: 1px 1px 1px #0000003a;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.562);
  }
  .modal{
    top:0;
    left:0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
  }
  
   .rates {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    padding: 0;
    margin: 1em;
    overflow:hidden;
    
  }
  .rates ul {
    padding: 0;
    margin: 1em;
    width: 30%;
   overflow: hidden;
   background-color: grey;
  }
  
   .modal li {
     display: flex;
     justify-content: flex-start;
     list-style: none;
     overflow: hidden;
     line-height: 4;
     padding:0;
     margin: 0;
     justify-self: stretch;
    border-bottom: 1px solid black ;
   }
   .modal li img{
    display: flex;
    justify-self: center;
     width: 20px;
     height: 20px;
     margin: 0.25em;
   }
   .modal li span{
     overflow: auto;
   }
   .modal li> label{
    display: flex;
    min-width: fit-content;
     height: 100%;
     width: 30%;
    justify-content: center;
    align-content: center;
   }
   .modal li input,textarea{
     border: none;
     justify-self: flex-end;
     margin: 0.25em;
     width: 60%;
   }
   .submitForm {
    display: flex;
    align-self: flex-start;
    background-color: #528227;
    justify-content: center;
    padding: 2em;
    color: #f5f5f5;
}
.modal{
  top: 5vh;
  left: 10vw;
  margin: 0;
  min-width: 300px;
  max-width: 900px;
  padding-top: 0;
  width: 80vw;
  height: fit-content;
  background-color: white;
  overflow: hidden;
  z-index: 100000;
}
.modal h1{
  display: flex;
  justify-content: space-between;
  padding-top:20px;
  padding-left: 10px;
  margin: 0;
  background-color: #1F2E81;
  color: white;
  text-decoration: underline;
  padding-bottom: 2px;
}
.submitForm {
  width: 100%;
  padding: 5px;
  margin-bottom: 0;
  padding: 0.5em;
}
  @media screen and (max-width: 900px){
    
     
      
  }
  @media screen and (max-width: 650px){
    .modal{
      top: 5vh;
      left: 10vw;
      margin: 0;
      padding-top: 0;
      width: 80vw;
      height: fit-content;
      background-color: white;
      overflow: hidden;
    }
    .modal h1{
      padding-top:20px;
      padding-left: 10px;
      margin: 0;
      background-color: #1F2E81;
      color: white;
      text-decoration: underline;
      padding-bottom: 2px;
    }
    .submitForm {
      width: 100%;
      padding: 5px;
      margin-bottom: 0;
      padding: 0.5em;
  }
  .rates{
    width: 90%;
    justify-content: flex-start;
    max-height: 35vh;
    overflow: scroll;
  }
  .rates ul{
    min-width: 200px;
    
    min-height: 12.5vh;
    font-size: 0.75em;
    padding: 0.5em;
  }
    
  
  }