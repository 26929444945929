  .services{
    display: flex;
    justify-content: space-evenly;
    width: 100vw;
    align-self: center;
    min-height: 300px;
  
  
  }
  .services> li{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0.5em;
    border: 1px solid black;
    width: 23vw;
    background-color: #1F2E81;
    padding: 0;
    box-shadow: 3px 3px 2px 0px rgba(0, 0, 0, 0.37);
    
  
    
  }
  .services img{  
    display: flex;
    width: 100%;
    margin: 0;
    justify-self: center;
    align-self: center;
  }
  .services h3{
    width: 75%;
    padding-bottom: 0;
    margin-bottom: 2px;
    color: #cbd4fd;
    border-bottom: 3px solid #cbd4fd;
  }
  .servicesContent{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding:1em;
    height: 50%;
  }
  .imgContainer{
    height: 35%;
    overflow: hidden;
  }
  .servicesContent ul{
    padding-top: 0;
    padding-bottom: 0;
  }
  .services p{
    background-color: white;
  
  }
  .serviceTitle{
    display: flex;
    background-color: grey;
    color: white;
    margin: auto;
    margin-top: -10%;
    width: 100%;
    height: 25%;
    justify-content: center;
    padding: 0.25em;
    opacity: 0.7;
  }
  .contact p{
    text-align: center;
  }
  .contact .socialContainer{
    display: flex;
    justify-self: center;
    justify-content: center;


  }
  .quoteReq {
    display: flex;
    align-self: center;
    background-color: #528227;
    padding: 0.5em;
    color: #f5f5f5;
}
 
  .servDesc>li{
    list-style: none;
    border-bottom: 1px dashed black;
  }

   .servHeader{
     border: cadetblue;
   }
   .services li p,h3{
     padding-left: 0.5em;
      padding-right: 0.5em;
   }
  label{
    background-color: grey;
  }
  @media screen and (max-width: 900px){
      .services{
        height: fit-content;
        align-content: center;
        justify-content: center;
      }
      .servicesContent{
        font-size: 0.75em;
        padding-top: 0;
        margin: 0;
      }
    .services>li{
      margin: 1.25em;
      width: 25vw;
      padding-bottom: 0;
      max-width: auto;
    }
    .serviceTitle{
      margin-top:-30%;
      font-size: 3vw;
    }
  
  }
  @media screen and (max-width: 650px){
    .imgContainer{
        display: flex;
        min-height: 37vh;
        padding: 0;
        margin: 0;
    }
    .servicesContent{
        font-size: 1em;
        padding: 1em;
      height: auto;
    }
    .services{width: 100vw;  
      flex-direction: column;
      align-content: space-evenly;
      justify-content: flex-start;
      padding:0;
      margin: 0;
    }
    .services>li{
      margin: 0;
      max-width: none;
      width:100vw;
      justify-content: flex-start;
     height: fit-content;
      border-left: none;
      border-right: none;
      padding: 0;
      box-shadow: none;
      
    
    }
    .imgContainer img{
        height: 100%;
        max-height: none;
        margin: 0;
        padding: 0;
    }
    .quoteReq {
        display: flex;
        background-color: #528227;
        width: 90vw;
        padding: 1em;
        justify-content: center;
        color: #f5f5f5;
    }
    
  
  }